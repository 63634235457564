/*--
/*  4.4 - Work CSS
/*----------------------------------------*/

.work-section {
    padding-top: 8em;
    padding-bottom: 8em;
}

// Work Tabs Menu 
.work-tabs-menu {
    padding-bottom: 0;

    & .nav {
        margin-left: -30px;
        margin-right: -30px;

        @media #{$small-mobile} {
            margin-left: -10px;
            margin-right: -10px;
        }

        & li {
            padding: 0 30px;
            margin-bottom: 10px;

            @media #{$small-mobile} {
                padding: 0 10px;
            }

            & a {
                font-size: 16px;
                color: $primary;
                font-weight: 600;
                text-transform: uppercase;
                position: relative;
                transition: $transition-base;

                @media #{$small-mobile} {
                    font-size: 14px;
                }

                &::before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: $white;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition-base;
                }

                &:hover,
                &.active {
                    color: $white;

                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

// Work tabs Content
.work-tab-content {
    padding-top: 4em;

    /* 
    @media #{$tablet-device} {
        padding-top: 40px;
    }

    @media #{$large-mobile} {
        padding-top: 30px;
    } */
}

// Tab Carousel 
.tab-carousel {
    background-color: rgba($white, 0.35);
}

// Single Project Slide 
.single-project-slide {
    position: relative;
    margin-bottom: -1px;

    & .thumb {
        & a {
            display: block;
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
                background-color: rgba($black, 0.35);
            }

            & img {
                width: 100%;
            }
        }
    }

    & .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10em;
        padding-top: 2em;
        padding-left: 1.5em;
        padding-right: 1.5em;

        /* @media #{$laptop-device, $desktop-device, $tablet-device, $large-mobile } {
            padding: 30px 30px;
        } */

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            background-color: #141414;
            left: 0;
            bottom: 0;
            transition: $transition-base;
        }

        & .subtitle {
            height: 2em;
            font-size: 14px;
            font-weight: 400;
            color: $white;
            font-family: $font-family-base;
        }

        & .title {
            height: 2em;
            font-size: 34px;
            font-weight: 600;
            font-family: $font-family-base;
            margin-top: 10px;

            @media #{$desktop-device} {
                font-size: 26px;
            }

            @media #{$large-mobile} {
                font-size: 22px;
            }

            & a {
                color: $white;
            }
        }
    }

    &:hover {
        & .content {
            &::before {
                height: 100%;
                transition: .6s;
            }

            & .subtitle {
                color: $body-color;
                position: relative;
                z-index: 9;
            }

            & .title {
                position: relative;
                z-index: 9;

                & a {
                    color: $primary;
                }
            }
        }
    }
}

//Tab Carousel Style
.tab-carousel {
    position: relative;

    & .tab-carousel-prev,
    & .tab-carousel-next {
        display: flex;
        align-items: center;
        width: 40px;
        height: 40px;
        transition: $transition-base;

        &:focus {
            border: 0px;
            outline: 0px;
        }

        &:after {
            display: none;
        }

        & i {
            opacity: 0;
            visibility: hidden;
            font-size: 30px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            width: 40px;
            height: 40px;

            &:hover {
                fill: $white;
                background: $primary !important;
                transition: .3s;
            }
        }
    }

    &:hover {

        & .tab-carousel-prev,
        & .tab-carousel-next {
            & i {
                opacity: 1;
                visibility: visible;
                background: #333333;
                transition: $transition-base;
            }
        }

        & .tab-carousel-prev {
            left: 70px;
            transition: $transition-base;

            @media #{$desktop-device, $tablet-device} {
                left: 40px;
            }

            @media #{$large-mobile} {
                left: 20px;
            }
        }

        & .tab-carousel-next {
            right: 70px;
            transition: $transition-base;

            @media #{$desktop-device, $tablet-device} {
                right: 40px;
            }

            @media #{$large-mobile} {
                right: 20px;
            }
        }
    }
}