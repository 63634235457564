/*--
/*  4.10 - Projects Details CSS
/*----------------------------------------*/

.page-banner-title {
    & .title {
        color: $primary;
    }

    & .project-subtitle {
        word-spacing: 0.1em;
        padding-bottom: 0.5em;
    }
}

.project-details-section {
    padding-top: 100px;

    @media #{$tablet-device} {
        padding-top: 70px;
    }

    @media #{$large-mobile} {
        padding-top: 50px;
    }

    & .details-label {
        font-family: $custom-font-family;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        white-space: nowrap;
    }

    & p {
        font-family: $custom-font-family;
        font-weight: 400;
    }
}

// Details Images 
.details-images {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 20em;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($white, 0.1);
    border-top: 1.5em solid rgba($primary, 0.75);
    border-bottom: 1.5em solid rgba($primary, 0.75);
    border-radius: 0.75em;
    padding: 1em;

    & img {
        border-radius: 0.5em;
        width: 100%;
        object-fit: cover;
        z-index: 1;
    }

    &::before {
        content: "Loading...";
        color: rgba($white, 0.2);
        letter-spacing: 0.025em;
        position: absolute;
    }

    @media #{$tablet-device, $large-mobile, $small-mobile} {
        border: unset;
        padding: 0;
        min-height: 10em;
        height: max-content;
        border-radius: 0.5em;
    }
}

// Details cheallenge 
.details-challenge {
    & .title {
        font-size: 60px;
        font-weight: 700;
        color: $primary;
        margin-top: 30px;

        @media #{$laptop-device} {
            font-size: 50px;
        }

        @media #{$desktop-device} {
            font-size: 42px;
        }

        @media #{$tablet-device, $large-mobile} {
            font-size: 38px;
        }

        @media #{$small-mobile} {
            font-size: 30px;

            & br {
                display: none;
            }
        }
    }
}

// Details Info 
.details-info {}

.single-info {
    margin-top: 2em;

    & .details-label {
        letter-spacing: 0.25em;
        font-weight: 500;
    }

    @media #{$tablet-device, $large-mobile, $small-mobile} {
        &:not(:first-child) {
            margin-top: 3em;
        }
    }

    & .details-label {
        font-size: 14px;
    }

    & p {
        font-size: 20px;
        color: $white;
        margin-top: 25px;

        @media #{$small-mobile} {
            font-size: 16px;
        }
    }
}

// Details Middle Content
.details-middle-content {
    border-top: 1px solid rgba($white, 0.075);

    & .title {
        font-size: 48px;
        line-height: 1.25em;
        font-weight: 700;
        color: $primary;
        margin-top: -15px;
        margin-bottom: 1em;

        @media #{$tablet-device} {
            font-size: 40px;
        }

        @media #{$large-mobile} {
            font-size: 30px;
        }
    }

    & .desc {
        font-size: 1.33em;
        line-height: 1.25em;
        margin-top: 1em;
    }
}

// Column Images
.column-images {
    padding-top: 58px;

    @media #{$tablet-device} {
        padding-top: 38px;

        & .row {
            gap: 1em !important;
        }
    }

    @media #{$large-mobile, $small-mobile} {
        padding-top: 18px;

        & .row {
            gap: 1em !important;
        }
    }
}

.quotation {
    width: 20em;
    align-self: flex-start;
    transform: translateY(-50%);

    &:first-child {
        margin-right: 0.5em;
    }

    &:last-child {
        margin-left: 0.3em;
    }

    @media #{$tablet-device} {
        font-size: 26px;
    }

    @media #{$large-mobile} {
        font-size: 24px;
    }

    @media #{$small-mobile} {
        font-size: 22px;
    }
}


.quote {
    margin-bottom: 2em;

    @media #{$tablet-device, $large-mobile, $small-mobile} {
        align-items: unset !important;
        gap: 1em !important;
    }
}

// Author Info
.author-info {

    & .author__image {
        height: 8em;
        width: 8em;
        border: 0.33em solid $primary;
        border-radius: 50%;
    }

    & .name {
        font-size: 1.75em;
        text-transform: uppercase;
        color: $primary;
        letter-spacing: 0.125em;
    }

    & p {
        color: $body-color;
        margin-top: 10px;
    }

    @media #{$large-mobile, $small-mobile} {
        & .author__image {
            height: 5em;
            width: 5em;
        }

        & .name {
            font-size: 1.5em;
        }
    }
}

// Details Social
.details-social {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
    padding-top: 100px;
    padding-bottom: 100px;

    @media #{$laptop-device} {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media #{$desktop-device} {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media #{$tablet-device} {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    & .social {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1.5em;

        @media #{$small-mobile} {
            padding-left: 0;
        }

        & li {
            & .social-icon {
                color: $white;

                & svg {
                    height: 1.2em;
                    fill: $white;
                    transition: $transition-base;
                }

                @media #{$small-mobile} {
                    font-size: 20px;
                }

                &:hover {
                    & svg {
                        fill: $primary;
                    }
                }
            }
        }
    }
}

// Reservation Form
.reservation-form-wrapper {
    border-top: 1px solid rgba($white, 0.15);

    & .title {
        font-size: 48px;
        font-weight: 700;
        color: $white;
        margin-top: -15px;

        @media #{$tablet-device} {
            font-size: 40px;
        }

        @media #{$large-mobile} {
            font-size: 30px;
        }
    }
}

.reservation-form {
    padding: 20px 50px 50px;
    border: 1px solid rgba($white, 0.15);
    margin-top: 60px;

    @media #{$tablet-device} {
        margin-top: 40px;
    }

    @media #{$large-mobile} {
        margin-top: 30px;
    }

    @media #{$small-mobile} {
        padding: 0px 30px 30px;
    }
}

// Next 
.next {
    margin-top: -10px;
    margin-bottom: -10px;

    & button {
        background-color: transparent;
        border: 0;
        font-size: 60px;
        font-weight: 700;
        color: $white;
        font-family: $custom-font-family;
        font-weight: 400;
        display: inline-block;
        line-height: 1;
        transition: $transition-base;

        @media #{$laptop-device} {
            font-size: 50px;
        }

        @media #{$desktop-device} {
            font-size: 42px;
        }

        @media #{$tablet-device, $large-mobile} {
            font-size: 38px;
        }

        @media #{$small-mobile} {
            font-size: 30px;
        }

        &:hover {
            color: $primary;
        }
    }
}