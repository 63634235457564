// About Section

.about-section {
    padding-top: 8em;
    padding-bottom: 8em;
}

.about-section-wrapper {
    margin: 0;
    width: 100%;
    display: flex;
    height: 45em;
    align-items: center;
    gap: 2em;

    /* @media #{$extraBig-device} {
        height: calc(100vh * 0.7);
    } 

    @media #{$laptop-device, $desktop-device} {
        height: 100vh;
    }*/

    @media #{$tablet-device, $large-mobile, $small-mobile} {
        flex-direction: column !important;
        gap: 8em !important;
        height: max-content !important;

        /* &>div {
            height: 100vh !important;
        } */
    }
}

// About Game
.about-game {
    width: 100%;
    height: 100%;
    background-color: $black;
    box-shadow: $default-shadow;
}

// About Content 
.about-content {
    width: 100%;
    height: 100%;

    & .title {
        font-size: 2em;
        font-weight: 600;
        color: $primary;

        @media #{$small-mobile} {
            font-size: 28px;
        }
    }

    & .about-text {
        font-size: 1.5em;
        line-height: 1.2em;
        color: $white;
        margin-top: 36px;

        @media #{$small-mobile} {
            font-size: 16px;
        }
    }

    & p {
        color: $highest-transparent-white;
        margin-bottom: 25px;
    }
}