.add__user {

    & .add__user-form_layout {
        display: grid;
        grid-template-columns: repeat(4, 15em);
        grid-template-rows: repeat(3, 2.5em) 1em;
        gap: 2.25em;
        align-items: center;
        justify-content: center;
    }

    & .add__user-form_first-name {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    & .add__user-form_middle-name {
        grid-column: 2/3;
        grid-row: 1/2;
    }

    & .add__user-form_last-name {
        grid-column: 3/4;
        grid-row: 1/2;
    }

    & .add__user-form_username {
        grid-column: 1/2;
        grid-row: 2/3;
    }


    & .add__user-form_email {
        grid-column: 2/4;
        grid-row: 2/3;
    }

    & .add__user-form_roles {
        grid-column: 1/3;
        grid-row: 3/4;
    }

    & .add__user-form_message {
        grid-column: 1/3;
        grid-row: 4/5;
        align-self: center;
        justify-self: center;
    }

    & .add__user-form_submit-button {
        grid-column: 3/4;
        grid-row: 3/5;
    }

    & .add_user-upload_image {
        grid-column: 4/5;
        grid-row: 1/5;
    }

    @media #{$tablet-device} {
        & .add__user-form_layout {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 12em);
            gap: 2.25em;
            align-items: center;
            justify-content: center;
        }
    }

    @media #{$large-mobile, $small-mobile, $extra-small-mobile} {
        width: 100% !important;

        & .add__user-form_layout {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(8, 2.5em) 0em;
            gap: 2.25em;
            align-items: center;
            justify-content: center;
        }

        & .add__user-form_first-name {
            grid-column: 1/4;
            grid-row: 1/2;
        }

        & .add__user-form_middle-name {
            grid-column: 1/4;
            grid-row: 2/3;
        }

        & .add__user-form_last-name {
            grid-column: 1/4;
            grid-row: 3/4;
        }

        & .add__user-form_username {
            grid-column: 1/4;
            grid-row: 4/5;
        }


        & .add__user-form_email {
            grid-column: 1/4;
            grid-row: 5/6;
        }

        & .add__user-form_roles {
            grid-column: 1/4;
            grid-row: 6/7;
        }

        & .add__user-form_message {
            grid-column: 1/4;
            grid-row: 7/8;
            align-self: center;
            justify-self: center;
        }

        & .add__user-form_submit-button {
            grid-column: 2/4;
            grid-row: 8/10;

            @media #{$small-mobile, $extra-small-mobile} {
                grid-column: 1/4;
                grid-row: 8/10;
            }
        }
    }
}