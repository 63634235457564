/*----------------------------------------*/


/*  01. Template default CSS
/*----------------------------------------*/

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}


/*-- Google Font --*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/*-- Common Style --*/

*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    background-color: $black;
    position: relative;
    outline: none;
    visibility: visible;
    line-height: 1.714;
    overflow-X: hidden;

    @supports(overflow-x: clip) {
        overflow-x: clip;
    }

    color: "";

    &[dir="rtl"] {
        text-align: right;
    }

    /* @media #{$extraBig-device,
        $laptop-device,
        $desktop-device,
        $tablet-device,
        $tablet-landscape,
        large-mobile,
        $large-mobile-shallow,
        $small-mobile,
        $extra-small-mobile} {
        
    } */

    & .bg-dark {
        background-color: $dull-black !important;
    }
}

// Managing Scroll Bar
body::-webkit-scrollbar {
    width: $scrollbar-width;
    /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    background: $black;
    /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: $primary;
    /* color of the scroll thumb */
    /* roundness of the scroll thumb */
    border: 0.5px solid $primary;
    /* creates padding around scroll thumb */
}

form {
    & textarea::-webkit-scrollbar {
        width: $scrollbar-width;
        /* width of the entire scrollbar */
    }

    & textarea::-webkit-scrollbar-track {
        background: $dull-black;
        /* color of the tracking area */
    }

    & textarea::-webkit-scrollbar-thumb {
        background-color: $primary;
        /* color of the scroll thumb */
        /* roundness of the scroll thumb */
        border: 0.5px solid $primary;
        /* creates padding around scroll thumb */
    }
}

// Managing Scroll Bar
div::-webkit-scrollbar {
    width: 1.5em;
    /* width of the entire scrollbar */
}

div::-webkit-scrollbar-track {
    background: $black;
    /* color of the tracking area */
}

div::-webkit-scrollbar-thumb {
    background-color: $primary;
    /* color of the scroll thumb */
    /* roundness of the scroll thumb */
    border: 0.5px solid $primary;
    /* creates padding around scroll thumb */
}

// Scrollbar for mozilla
html {
    scrollbar-color: $primary $black;
    /* thumb and track color */
    scrollbar-width: thin;
}

a,
button {
    transition: $transition-base;
    outline: 0;

    &:focus {
        outline: 0;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

p:last-child {
    margin-bottom: 0;
}

ul,
ol {
    padding: 0;
    list-style: none;
    margin: 0;
}

img {
    max-width: 100%;
}

// Main Wrapper 
.main-wrapper {
    background-color: $dull-black;
}

.nav-tabs {
    border-bottom: 0px;

    .nav-link {
        cursor: pointer;
        border: 0;
        margin-bottom: 0;
        padding: 0;

        &.active {
            background-color: transparent;
            border: 0;
        }

        &:hover {
            border: 0;
        }
    }
}

// Custom Container
.container {
    @media #{$extraBig-device} {
        max-width: 1920px;
        padding: 0 70px;
    }
}


/* Section Style */

.section,
.main-wrapper {
    float: left;
    width: 100vw;
    background-color: rgba($color: $black, $alpha: 0);
    overflow-x: clip;
}


/* Section Padding */

.section-padding {
    padding-top: 100px;
    padding-bottom: 100px;

    @media #{$laptop-device} {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media #{$desktop-device} {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media #{$tablet-device} {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.section-padding-02 {
    padding-top: 100px;

    @media #{$laptop-device} {
        padding-top: 100px;
    }

    @media #{$desktop-device} {
        padding-top: 100px;
    }

    @media #{$tablet-device} {
        padding-top: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
    }
}

.section-padding-03 {
    padding-bottom: 100px;

    @media #{$laptop-device} {
        padding-bottom: 100px;
    }

    @media #{$desktop-device} {
        padding-bottom: 100px;
    }

    @media #{$tablet-device} {
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-bottom: 60px;
    }
}

// Section Title 
.section-title {
    & .title {
        font-size: 2em;
        font-weight: 600;
        color: $primary;

        @media #{$large-mobile} {
            font-size: 40px;
        }

        @media #{$small-mobile} {
            font-size: 28px;
        }
    }
}

// Section Title 2
.section-title-2 {
    & .title {
        font-size: 14px;
        font-weight: 400;
        font-family: $custom-font-family;
        color: $body-color;
        text-transform: uppercase;
    }
}

// tab Content 
.tab-content {
    & .tab-pane {
        display: block;
        overflow: hidden;
        height: 0;
        visibility: hidden;
        max-width: 100%;
        opacity: 0;
        transition: $transition-base;

        &.active {
            height: auto;
            visibility: visible;
            opacity: 1;
            overflow: visible;
            animation: fadeInUp 0.8s;
        }
    }
}

// Page Banner Title 
.page-banner-title {
    padding-top: 247px;
    position: relative;
    z-index: 0;

    @media #{$large-mobile, $tablet-device} {
        padding-top: 187px;
    }

    @media #{$small-mobile} {
        padding-top: 147px;
    }

    & .title {
        font-size: 60px;
        font-weight: 700;
        color: $white;

        & span {
            color: $primary;
        }

        @media #{$laptop-device} {
            font-size: 50px;
        }

        @media #{$desktop-device} {
            font-size: 42px;
        }

        @media #{$tablet-device, $large-mobile} {
            font-size: 38px;
        }

        @media #{$small-mobile} {
            font-size: 30px;

            & br {
                display: none;
            }
        }
    }

    & p {
        margin-bottom: 0;
        margin-top: 25px;
        text-transform: uppercase;
        font-size: 16px;
    }

    & .meta {
        display: flex;
        margin-top: 25px;

        & li {
            position: relative;

            &+li {
                &::before {
                    content: '/';
                    color: #333333;
                    margin: 0 15px;
                    font-size: 13px;
                }
            }

            & a {
                font-size: 13px;
                color: rgba($white, 0.5);
                text-transform: uppercase;
                transition: $transition-base;

                &:hover {
                    color: #f89200;
                }
            }
        }
    }
}

// Project Menu 
.filter-menu {
    padding-top: 85px;

    & .menu-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -25px;
        margin-right: -25px;

        @media #{$large-mobile} {
            margin-left: -15px;
            margin-right: -15px;
        }

        & li {
            padding: 0 25px;
            font-size: 16px;
            font-family: $custom-font-family;
            font-weight: 400;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: rgba($white, 0.5);
            cursor: pointer;

            @media #{$large-mobile} {
                padding: 0 15px;
            }

            &.active {
                color: $primary;
            }
        }
    }
}

// Load More 
.load-more {
    margin-top: -10px;
    margin-bottom: -10px;

    & .more {
        background-color: transparent;
        border: 0;
        font-size: 18px;
        font-family: $custom-font-family;
        font-weight: 400;
        color: #666666;
        letter-spacing: 10px;
        text-transform: uppercase;

        &:hover {
            color: $primary;
        }
    }
}


/*-------- Scroll To Top Style Css --------*/

.scroll-to-top {
    position: fixed;
    right: 30px;
    bottom: -60px;
    z-index: 2;
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
    display: block;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    border: 0;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    background-color: $primary;

    &.show {
        visibility: visible;
        opacity: 1;
        bottom: 50px;
    }

    & svg {
        height: 20px !important;
        fill: $dull-black;
    }

    &:hover {
        background-position: right center;

        & svg {
            & path {
                transform: translateX(0%) !important;
            }
        }
    }
}

// No select text
.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}