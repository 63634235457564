.loading-page {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    font-size: 10em;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loading-animation svg {
    height: 0.5em;
}

.loading-animation {
    position: absolute;
    overflow: hidden;
    height: 1em;
    width: 1em;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.02em solid rgba($color: $primary, $alpha: 0.3);
    border-radius: 50%;
    box-sizing: content-box;
    padding: 0;
    transform: translate(-50%, -50%);
    opacity: 1;
    animation: spinner 3s infinite;
    transition: 1200ms;
    transition-timing-function: cubic-bezier(1.2, 0, 0.2, 1.2);
}

@keyframes spinner {
    50% {
        transform: translate(-50%, -50%);
        border: 0.02em solid rgba($primary, 1);
        padding: 30px;
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) rotate(360deg);
        border: 2em solid rgba($primary, 0.03);
        padding: 17rem;
    }
}

@media only screen and (max-width: 575px),
only screen and (max-width: 767px) {
    @keyframes spinner {
        50% {
            transform: translate(-50%, -50%);
            border: 0.02em solid rgba($primary, 1);
            padding: 30px;
        }
        100% {
            opacity: 1;
            transform: translate(-50%, -50%) rotate(360deg);
            border: 2em solid rgba($primary, 0.03);
            padding: 10rem;
        }
    }
}