//=======================
// Developer Variable
//=======================
// scss-docs-start social-colors-map
$social-colors: ("android": #7AC157, "apple": #B8B8B8, "behance": #1869FF, "codepen": #000000, "dribbble": #EA4C8A, "dropbox": #007EE5, "evernote": #78D525, "facebook": #4867AA, "github": #313131, "googleDrive": #1DA462, "googleEarth": #4285F4, "googleGlass": #EA4335, "googleMaps": #5083C3, "googlePlay": #01B9FD, "googlePlus": #DD5144, "google": #4285F4, "instagram": #B23A94, "css3": #0277BD, "html5": #E44D26, "javascript": #F9DC3D, "python": #0C9DBF, "lastfm": #E31B23, "linkedin": #007BB6, "paypal": #002F86, "pinterest": #BD081B, "pocket": #EF3E56, "polymer": #F87292, "rss": #F99C3A, "share": #2C9CFF, "stackoverflow": #F38024, "steam": #15497B, "twitter": #1DA1F2, "vk": #5181B8, "wikipedia": #E9E9E9, "windows": #0078D6, "s500px": #000000, "s8tracks": #122D4B, "amazon": #F79B34, "blogger": #F06A35, "delicious": #0000FE, "disqus": #2E9EFE, "flattr": #7AB831, "flickr": #FE0084, "odnoklassniki": #F58220, "outlook": #0072C6, "playstation": #07418E, "reddit": #FF4500, "skype": #00A9F0, "slideshare": #0077B5, "soundcloud": #FE4900, "tumblr": #36465D, "twitch": #6441A4, "vimeo": #1AB7EA, "whatsapp": #189D0E, "xbox": #107C0F, "yahoo": #4101AF, "youtube": #FE0000, );
// scss-docs-end theme-colors-map
// Responsive Variables
$extraBig-device: "only screen and (min-width: 1600px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$tablet-landscape: "only screen and (max-height: 600px)";
$large-mobile: "only screen and (max-width: 767px)";
$large-mobile-shallow: "only screen and (max-width: 767px) and (max-height: 700px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";
// Zoom levels 
$extraBig-device-zoomLevel: 140 !default;
//===============================
// Bootstrap Variables Overright
//===============================
// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$adobe-gray: #606060 !default;
$brahmware-gray: $adobe-gray !default;
$black: #000 !default;
// fusv-disable
$grays: ("100": $gray-100, "200": $gray-200, "300": $gray-300, "400": $gray-400, "500": $gray-500, "600": $gray-600, "700": $gray-700, "800": $gray-800, "900": $gray-900) !default;
// fusv-enable
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #da2929 !default;
$orange: #fd7e14 !default;
$orange-red: #F74B1C !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$dull-black: #111111 !default;
// scss-docs-start colors-map
$colors: ("blue": $blue, "indigo": $indigo, "purple": $purple, "pink": $pink, "red": $red, "orange": $orange, "yellow": $yellow, "green": $green, "teal": $teal, "cyan": $cyan, "white": $white, "gray": $gray-600, "gray-dark": $gray-800) !default;
// scss-docs-end colors-map
$primary: #D99938 !default;
$complementary: #F08A32 !default;
$secondary: #59A5DE !default;
$success: #62D319 !default;
$info: $cyan !default;
$warning: #F08E1C !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: #090909 !default;
$twitter: #00ccff !default;
$behance: #0066ff !default;
$facebook: #1674EA !default;
$linkedin: #0173AF !default;
// scss-docs-start theme-colors-map
$theme-colors: ("primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark) !default;
// scss-docs-end theme-colors-map
// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-negative-margins: true;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: (0: 0, 1: $spacer * 0.3125, 2: $spacer * 0.625, 3: $spacer * 0.9375, 4: $spacer * 1.25, 5: $spacer * 1.625, 6: $spacer * 1.875, 7: $spacer * 2.1875, 8: $spacer * 2.5, 9: $spacer * 2.8125, 10: $spacer * 3.125, );
$body-color: rgba(255, 255, 255, .75);
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// scss-docs-start grid-breakpoints
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1600px, );
// scss-docs-end grid-breakpoints
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1200px, xxl: 1200px, );
// scss-docs-end container-max-widths
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 30px;
//
$gutters: $spacers;
// Transition
$transition-base: all 600ms ease 0s;
$transition-specific: 600ms ease 0s;
// Typography
//
// Font, line-height, and color for body text, headings, and more.
// stylelint-enable value-keyword-case
$font-family-base: 'Montserrat',
sans-serif;
$custom-font-family: 'Roboto',
sans-serif;
// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-base: 0.875rem;
//
//
$line-height-base: 1.5;
//
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
//
$headings-font-family: 'Montserrat',
sans-serif;
$headings-font-weight: 500;
$headings-line-height: 1;
$headings-color: null;
// scrollbar width
$scrollbar-width: 8px;
// base opacity
$opacity-base: 0.6;
$opacity-less: 0.4;
$opacity-very-less: 0.3;
$opacity-high: 0.8;
$opacity-very-high: 0.99;
$opacity-full: 1;
// default scaling
$scale-base: 1.05;
$scale-high: 1.2;
// default strokes
$stroke-thin: 1.5px;
$stroke-lite: 1px;
$stroke-thinner: 0.5px;
$stroke-default: 5px;
$radius-default: 2px;
// default blurs
$blur-default: blur(3px);
// animation time
$default-animation-time: 1200ms;
// transparent backgrounds
$highly-transparent-black: rgba($color: $black, $alpha: $opacity-very-less);
$lower-transparent-black: rgba($color: $black, $alpha: $opacity-base);
$loweest-transparent-black: rgba($color: $black, $alpha: 95);
$highest-transparent-white: rgba($color: $white, $alpha: 0.8);
$medium-transparent-white: rgba($color: $white, $alpha: 0.5);
$lower-transparent-white:rgba($color: $white, $alpha: 0.2);
$medium-transparent-black: rgba($color: $black, $alpha: 0.5);
$medium-transparent-dull-black: rgba($color: $dull-black, $alpha: 0.5);
$higher-transparent-dull-black: rgba($color: $dull-black, $alpha: 0.8);
$highest-transparent-dull-black: rgba($color: $dull-black, $alpha: 0.95);
// blurs for backdrop
$low-blur: blur(5px);
$high-blur: blur(10px);
$higher-blur: blur(20px);
// Reduce Div Corner
$tiny-border-radius: 2px;
$medium-border-radius: 20px;
// All relative paddings
// All relative paddings
$padding-20: 20%;
$padding-10: 10%;
$padding-5: 5%;
$padding-3: 3%;
$padding-2: 2%;
// All relative margins
$margin-10: 10%;
$margin-5: 5%;
$margin-2: 2%;

// default shadow
$default-shadow: 0 0 10px 0px $lower-transparent-black;