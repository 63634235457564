// Pricing table
.home-pricing-section {

    padding: 8em 0;

    // Single Pricing Box 
    & .home-pricing-table-wrapper {
        /* margin-top: -320px;
        position: relative;
        z-index: 2; */
        padding: 0;
        padding-top: 4em;

        @media #{$large-mobile} {
            margin-top: 0;
            padding-top: 30px;
        }

        & .row {
            & .col {
                &:nth-child(n+1) {
                    & .single-pricing-box {
                        & .icon-box {
                            & svg {
                                animation: shake 5000ms ease-in-out 0ms infinite;
                            }
                        }
                    }
                }

                &:nth-child(n+2) {
                    & .single-pricing-box {
                        & .icon-box {
                            & svg {
                                animation: shake 5000ms ease-in-out 1000ms infinite;
                            }
                        }
                    }
                }

                &:nth-child(n+3) {
                    & .single-pricing-box {
                        & .icon-box {
                            & svg {
                                animation: shake 5000ms ease-in-out 2000ms infinite;
                            }
                        }
                    }
                }

                &:nth-child(n+4) {
                    & .single-pricing-box {
                        & .icon-box {
                            & svg {
                                animation: shake 200ms ease-in-out 3000ms infinite;
                            }
                        }
                    }
                }

                @keyframes shake {
                    0% {
                        transform: translate(0.5px, 0.5px) rotate(0deg);
                    }

                    10% {
                        transform: translate(-0.5px, -0.5px) rotate(-1deg);
                    }

                    20% {
                        transform: translate(-1.5px, 0px) rotate(1deg);
                    }

                    30% {
                        transform: translate(1.5px, 1px) rotate(0deg);
                    }

                    40% {
                        transform: translate(0.5px, -0.5px) rotate(1deg);
                    }

                    50% {
                        transform: translate(-0.5px, 1px) rotate(-1deg);
                    }

                    60% {
                        transform: translate(-1.5px, 0.5px) rotate(0deg);
                    }

                    70% {
                        transform: translate(1.5px, 0.5px) rotate(-1deg);
                    }

                    80% {
                        transform: translate(-0.5px, -0.5px) rotate(1deg);
                    }

                    90% {
                        transform: translate(0.5px, 1px) rotate(0deg);
                    }

                    100% {
                        transform: translate(0.5px, 0.5px) rotate(-1deg);
                    }
                }

                &:hover {
                    & .single-pricing-box {
                        & .icon-box {
                            & svg {
                                animation-play-state: paused;
                            }
                        }
                    }
                }
            }
        }
    }

    // Single Pricing Box 
    & .single-pricing-box {
        height: 100%;
        background-color: $dull-black;
        box-shadow: $default-shadow;

        & .pricing-inner {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 5em 1em 3em;
            gap: 5em;

            & .popular {
                position: absolute;
                transform: translateY(-5.2em);
                color: $primary;
                font-size: 1.2em;
                letter-spacing: 0.1em;
                line-height: 1em;
                text-transform: uppercase;
                font-weight: 600;
                padding: 1em;
                margin: 1em;
                border-radius: 0 0 $medium-border-radius $medium-border-radius;
                background-color: rgba($color: $primary, $alpha: $opacity-very-less);
                /* 
                
                letter-spacing: 3px;
                padding: 7px 30px;
                border-radius: 0px 0px 35px 35px;
                background-color: rgba($color: $primary, $alpha: $opacity-very-less);
                @media #{$small-mobile} {
                    font-size: 14px;
                    padding: 5px 30px;
                } */
            }

            & .icon-box {
                & .pricing-icon {
                    & svg {
                        height: 8em;
                        fill: $primary;
                    }
                }
            }

            & .price {
                height: max-content;
                justify-content: center;
                align-items: center;
                display: flex;
                gap: 0.25em;
                font-size: 2em;
                font-weight: 600;
                color: $primary;

                & amount {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.3em;

                    & curr {
                        color: $white;
                    }
                }

                & month {
                    color: $white;
                    font-size: 0.5em;
                    transform: translateY(0.25em);
                }
            }

            & .title {
                font-size: 1.5em;
                font-weight: 700;
                color: $white;
            }

            & .price-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                height: 10em !important;
                gap: 1em;
                font-size: 0.8em;
                font-weight: 500;

                & .divider-line {
                    &:not(:last-child) {
                        height: $stroke-lite;
                        width: 2em;
                        border-bottom: $stroke-lite solid $primary;
                    }
                }
            }

            & .btn {
                font-weight: 500;
                width: 80%;
            }
        }
    }
}


/* .single-pricing-box {
    background-color: $dark;
    height: 100%;
    border: $stroke-lite solid $primary;
    text-align: center;
    position: relative;
    & .pricing-iner {
        display: flex;
        flex-direction: column;
        padding: 100px 60px 60px;
        @media #{$desktop-device} {
            padding: 80px 40px 40px;
        }
        @media #{$tablet-device, $small-mobile} {
            padding: 70px 30px 30px;
        }
    }
    @media #{$large-mobile} {
        margin-top: 30px;
    }
    & .popular {
        position: absolute;
        left: 50%;
        top: 0px;
        color: $primary;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 3px;
        padding: 7px 30px;
        text-transform: uppercase;
        transform: translateX(-50%);
        border-radius: 0px 0px 35px 35px;
        background-color: #33281e;
        @media #{$small-mobile} {
            font-size: 14px;
            padding: 5px 30px;
        }
    }
    & .icon-box {
        height: 150px;
        & svg {
            fill: $primary;
            height: 8em;
        }
        @media #{$desktop-device} {
            font-size: 80px;
        }
        @media #{$tablet-device, $small-mobile} {
            font-size: 60px;
        }
    }
    & .title {
        margin: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        height: 60px;
        vertical-align: middle;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 2px;
        @media #{$tablet-device, $small-mobile} {
            margin-top: 42px;
        }
    }
    & .price {
        margin: 0% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: $white;
        font-weight: 700;
        line-height: 1;
        height: 60px;
        @media #{$desktop-device} {
            font-size: 30px;
        }
        @media #{$tablet-device, $small-mobile} {
            font-size: 20px;
        }
        & curr {
            font-weight: 500;
            margin-left: 5px;
            margin-right: 5px;
            color: $primary;
        }
        & span {
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 15px;
            font-weight: 400;
            color: $primary;
            transform: translateY(5px);
            @media #{$tablet-device, $small-mobile} {
                font-size: 10px;
                margin-left: 5px;
                margin-right: 5px;
                transform: translateY(2px);
            }
        }
    }
    & .price-list {
        position: relative;
        height: 160px;
        margin-bottom: 25px;
        margin-top: 45px;
        & li {
            position: relative;
            font-size: 14px;
            padding: 22px 0px;
            text-transform: uppercase;
            border-top: 1px solid $primary;
            @media #{$tablet-device, $small-mobile} {
                & br {
                    display: none;
                }
            }
        }
    }
    & .btn {
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
        height: 50px;
        line-height: 46px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: $custom-font-family;
    }
} */