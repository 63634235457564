.web-admin {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40em;

    & .rectangle {
        height: 20em;
        width: 30em;
        background-color: $primary;
    }

    & .if-statement {
        align-self: flex-start;
        display: flex;
        margin: 0;
        padding: 0;
        font-size: 2em;
        color: $primary;
        font-weight: 500;

        & p {
            color: white;
            padding: 0 0.2em;
            font-weight: 600;
        }

        &.if-open-statement {
            padding-bottom: 0.5em;
        }

        &.if-close-statement {
            padding-top: 0.5em;
        }
    }

    & form {
        display: flex;
        flex-direction: column;
        gap: 1em;
        background-color: rgba($white, 0.1);
        padding: 1.5em 2em;
        border-radius: $medium-border-radius;
    }

    & input {
        border: 0;
        background: none;
        border-bottom: 1px solid rgba($white, 0.15);
        font-size: 1em;
        color: $primary;
        padding: 0;
        outline: none;
        transition: $transition-base;
        flex-grow: 1;
        caret-color: $red !important;

        &.submit-btn {
            border-bottom: none !important;
            background-color: rgba($white, 0.05);
            font-weight: 500;
            transition: $transition-base;
            border-radius: $medium-border-radius;
            margin-left: 0.33em !important;

            &:hover,
            &:focus {
                background-color: rgba($white, 0.15);
                color: $complementary;
            }
        }

        &:focus {
            border-color: $white;
        }
    }

    & .one-line {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & p {
            margin: 0;
            font-weight: 600;
        }

        & .anotate-web-admin {
            color: $white;
        }

        & .anotate-field {
            color: $primary;
        }

        & .punctuation {
            color: $red;
            padding: 0 0.2em;
        }

        & input {
            margin-left: 1em;
            padding: 0 0.33em;
        }
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-webkit-autofill:active,
    & textarea:-webkit-autofill,
    & textarea:-webkit-autofill:hover,
    & textarea:-webkit-autofill:focus,
    & select:-webkit-autofill,
    & select:-webkit-autofill:hover,
    & select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.845) inset !important;
        transition: black 5000s ease-in-out 0s;
    }

    & input:-webkit-autofill {
        -webkit-text-fill-color: $complementary !important;
    }
}