.edit__user-form {

    & .edit__user-form_layout {
        display: grid;
        grid-template-columns: repeat(4, 15em);
        grid-template-rows: repeat(3, 2.5em) 1em;
        gap: 2.25em;
        align-items: center;
        justify-content: center;
    }


    & .edit_user-upload_image {
        grid-column: 1/2;
        grid-row: 1/5;
    }

    & .edit__user-form_first-name {
        grid-column: 2/3;
        grid-row: 1/2;
    }

    & .edit__user-form_middle-name {
        grid-column: 3/4;
        grid-row: 1/2;
    }

    & .edit__user-form_last-name {
        grid-column: 4/5;
        grid-row: 1/2;
    }

    & .edit__user-form_email {
        grid-column: 2/4;
        grid-row: 2/3;
    }

    & .edit__user-form_roles {
        grid-column: 2/4;
        grid-row: 3/4;
    }

    & .edit__user-form_message {
        grid-column: 2/4;
        grid-row: 4/5;
        align-self: center;
        justify-self: center;
    }

    & .edit__user-form_submit-button {
        grid-column: 4/5;
        grid-row: 3/5;
    }

}