/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/

// Slider Section 
.slider-section {
    position: relative;
    & .social {
        position: absolute;
        display: flex;
        z-index: 1;
        left: var(--bs-gutter-x);
        top: 95%;
        @media #{$small-mobile} {
            left: 20px;
            bottom: 20px;
        }
        @media #{$large-mobile-shallow} {
            bottom: 0;
            top: 90%;
            left: 60%;
        }
        @media #{$tablet-landscape} {
            bottom: 0;
            left: 60%;
            top: 75%;
        }
        & li {
            &+li {
                margin-left: 45px;
                @media #{$large-mobile} {
                    margin-left: 30px;
                }
                @media #{$small-mobile} {
                    margin-left: 20px;
                }
            }
            & .social-icon {
                margin: 0 !important;
                padding: 0 !important;
                margin-right: 12px !important;
                & svg {
                    height: 18px !important;
                    fill: $white;
                    transition: $transition-base;
                }
                &:hover {
                    & svg {
                        transform: scale($scale-high);
                        fill: $primary;
                        transition: $transition-base;
                    }
                }
                @media #{$large-mobile, $small-mobile} {
                    & svg {
                        height: 15px !important;
                    }
                }
            }
        }
    }
}

// Single Slider 
.single-slider {
    min-height: 100vh;
    height: auto;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    & .line {
        width: 1px;
        height: 100%;
        background-color: $white;
        position: absolute;
        top: 0;
        z-index: -2;
        display: block;
        opacity: 0.15;
        &:nth-of-type(1) {
            left: 25%;
        }
        &:nth-of-type(2) {
            left: 50%;
        }
        &:nth-of-type(3) {
            left: 75%;
        }
    }
    &::before {
        position: absolute;
        content: '';
        background: $black;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.3;
    }
}

// Video Background
.single-slider {
    & .video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        opacity: 0.5;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        & video {
            position: absolute;
            min-height: 100vh;
            min-width: 100vw;
            width: auto;
            height: auto;
        }
    }
}

// Slider Content 
.slider-content {
    opacity: 0;
    & .sub-title {
        font-size: 22px;
        font-family: $font-family-base;
        font-weight: 400;
        color: $white;
        @media #{$laptop-device, $desktop-device, $tablet-device} {
            font-size: 22px !important;
        }
        @media #{$large-mobile} {
            font-size: 20px !important;
        }
        @media #{$small-mobile} {
            font-size: 18px !important;
        }
    }
    & .main-title {
        font-size: 130px;
        font-weight: 700;
        color: $white;
        line-height: 1;
        margin-top: 53px;
        @media #{$laptop-device, $desktop-device} {
            font-size: 100px;
            margin-top: 43px;
        }
        @media #{$tablet-device} {
            font-size: 80px;
            margin-top: 43px;
        }
        @media #{$large-mobile} {
            font-size: 40px;
            margin-top: 33px;
        }
        @media #{$small-mobile} {
            font-size: 30px;
        }
        & span {
            color: $primary;
        }
    }
    & p {
        font-family: $custom-font-family;
        color: $white;
        margin-top: 50px;
        margin-bottom: 75px;
        @media #{$laptop-device, $desktop-device, $tablet-device} {
            margin-top: 40px;
            margin-bottom: 40px;
            font-size: 20px !important;
        }
        @media #{$large-mobile} {
            margin: 30px 0;
            font-size: 18px !important;
        }
        @media #{$small-mobile} {
            font-size: 15px !important;
        }
    }
    & .slider-btn {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        background-color: rgba($black, $opacity-less);
        border: $stroke-lite solid $primary;
        border-radius: $radius-default;
        transition: $transition-base;
        & span {
            display: inline-block;
            font-size: 17px;
            font-weight: 500;
            color: $primary;
            transition: $transition-base;
        }
        @media #{$laptop-device, $desktop-device, $tablet-device, $large-mobile} {
            & span {
                font-size: 14px;
            }
        }
        @media #{$small-mobile} {
            & span {
                font-size: 14px;
            }
        }
        &:hover {
            background-color: $primary;
            transition: $transition-base;
            opacity: 1;
            & span {
                color: $black;
                transform: scale(1.05);
            }
        }
    }
    @media #{$large-mobile, $small-mobile} {
        transform: translateY(70%);
    }
}

// Single Slider Animation
.swiper-slide-active {
    .animation-style-01 {
        & .slider-content {
            opacity: 1;
            & .sub-title {
                animation-name: fadeInUp;
                animation-delay: 0.5s;
                animation-duration: 1.3s;
                animation-fill-mode: both;
            }
            & .main-title {
                animation-name: fadeInUp;
                animation-delay: 1s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & p {
                animation-name: fadeInUp;
                animation-delay: 1.5s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & .slider-btn {
                animation-name: fadeInLeft;
                animation-delay: 2s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
        }
    }
}