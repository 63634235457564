.not-web-admin {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40em;

    & .else-statement {
        align-self: flex-start;
        margin: 0;
        padding: 0;
        font-size: 2em;
        color: $primary;
        font-weight: 500;

        &.else-open-statement {
            padding-bottom: 0.5em;
        }

        &.else-close-statement {
            padding-top: 0.5em;
        }
    }

    & .other-tabs {
        display: grid;
        background-color: rgba($white, 0.1);
        border-radius: $medium-border-radius;
        padding: 1.5em 2em;
        column-gap: 8em;
        row-gap: 1.5em;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);

        & li:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
        }

        & li:nth-child(2) {
            grid-area: 1 / 2 / 2 / 3;
        }

        & li:nth-child(3) {
            grid-area: 2 / 1 / 3 / 3;
            justify-self: center;
        }
    }
}