.icon-branding {

    .anchor-part {
        animation: move 2s linear infinite;
    }

    .anchor-part--2 {
        animation: move2 2s linear infinite;
    }

    .pen-part {
        transform-origin: 140px 180px;
        animation: bounce2 2s ease-in-out infinite;
    }

    .ruler-part {
        transform-origin: 140px 180px;
        animation: bounce 1s ease-in-out infinite;
    }

    .circle-part {
        transform-origin: 139.8px 167.8px;
        animation: rotate 4s linear infinite;
    }

    .cursor-part {
        animation: bounceBFt2 2s ease-in-out infinite;
    }

    @keyframes move {

        0%,
        100% {
            transform: translate(1px, 1px);
        }

        50% {
            transform: translate(-1px, -1px);
        }
    }

    @keyframes move2 {

        0%,
        100% {
            transform: translate(-1px, -1px);
        }

        50% {
            transform: translate(1px, 1px);
        }
    }

    .hover {
        animation-play-state: paused;
    }

    &:hover {

        .hover {
            animation-play-state: running;
        }
    }
}

.icon-business_solutions {

    .gear-b {
        animation: rotate-reverse 6s;
    }

    .gear-s {
        animation: rotate 12s linear infinite;
    }

    #XMLID_87_,
    #XMLID_85_,
    #XMLID_83_ {
        animation: twinkle 3s ease-in-out infinite;
    }

    #XMLID_85_ {
        animation-delay: .4s;
    }

    #XMLID_83_ {
        animation-delay: .6s;
    }

    .line-t,
    .line-m,
    .line-b {
        animation: shrink 1.8s ease-in-out infinite;
    }

    .line-m {
        animation-delay: .3s;
    }

    .line-b {
        animation-delay: .6s;
    }

    .code {

        &-letter_o {
            animation: typingO 3s linear infinite;
        }

        &-letter_d {
            animation: typingD 3s linear infinite;
        }

        &-letter_e {
            animation: typingE 3s linear infinite;
        }

        @keyframes typingE {

            20.1%,
            80% {
                fill: transparent;
            }

            20%,
            80.1% {
                fill: #fff;
            }
        }

        @keyframes typingD {

            30.1%,
            70% {
                fill: transparent;
            }

            30%,
            70.1% {
                fill: #fff;
            }
        }

        @keyframes typingO {

            40.1%,
            60% {
                fill: transparent;
            }

            40%,
            60.1% {
                fill: #fff;
            }
        }
    }

    .hover {
        animation-play-state: paused !important;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &:hover {
        .hover {
            animation-play-state: running !important;
        }
    }
}

.icon-about-us {

    .gear-s,
    .gear-m {
        animation: rotate;
        animation-duration: 6s;
    }

    .gear-b {
        animation: rotate-reverse 6s;
    }

    .second {
        transform-origin: 223.6px 132.8px;
        animation: rotate 1s linear infinite;
    }

    .minute {
        transform-origin: 223.6px 132.8px;
        animation: rotate 60s linear infinite;
    }

    .hour {
        transform-origin: 223.6px 132.8px;
        animation: rotate 3600s linear infinite;
    }

    .hover {
        animation-play-state: paused;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &:hover {
        .hover {
            animation-play-state: running;
        }
    }
}

.icon-eComm {

    .overlay {
        transform: translate(82px, -214px);
    }

    .bubble-b {
        animation: bubble-b 3.4s linear infinite;
    }

    .bubble-m {
        animation: bubble-m 3.4s linear infinite;
    }

    .bubble-t {
        animation: bubble-t 3.4s linear infinite;
    }

    .hand,
    .bag-f,
    .bag-b,
    .bag-f--tag {
        transform-origin: 320px 200px;
        animation: bounce2 1.6s ease-in-out infinite;
    }

    .bag-bb {
        animation-delay: .4s;
    }

    .bag-f--tag,
    .bag-bb {
        transform-origin: 200px 190px;
        animation: bounce 1.6s ease-in-out infinite;
    }

    .bag-b {
        transform-origin: 160px 160px;
        animation: bounce 1.6s ease-in-out infinite;
    }

    .credit-card {

        circle:nth-of-type(1) {
            animation: shift 6s ease-in-out infinite;
        }

        circle:nth-of-type(2) {
            animation: shift2 6s ease-in-out infinite;
        }

        @keyframes shift {
            0% {
                transform: translateX(0);
                opacity: 1;
            }

            25%,
            75% {
                opacity: 0.7;
            }

            50% {
                transform: translateX(10px);
                opacity: 1;
            }
        }

        @keyframes shift2 {
            0% {
                transform: translateX(0);
                opacity: 1;
            }

            25%,
            75% {
                opacity: 0.7;
            }

            50% {
                transform: translateX(-10px);
                opacity: 1;
            }
        }
    }

    .hover {
        animation-play-state: paused;
    }

    &:hover {
        .hover {
            animation-play-state: running;
        }
    }
}

.icon-mobApps {

    .gear {
        animation: rotate 30s linear infinite;
    }

    .line-t,
    .line-m,
    .line-b {
        animation: shrink 2.2s ease-in-out infinite;
    }

    .play-btn polygon {
        animation: twinkle 3s linear infinite;
    }

    .line-m {
        animation-delay: .3s;
    }

    .line-b {
        animation-delay: .6s;
    }

    .ruler {
        transform-origin: 300px 200px;
        animation: bounceBFt2 2s ease-in-out infinite;
    }

    .hook {
        transform-origin: 148px 24px;
        animation: bounce3 1.6s ease-in-out infinite;
    }

    .play-bar--play {
        opacity: 1;
    }

    .play-bar--pause {
        opacity: 0;
    }

    .hover {
        animation-play-state: paused !important;
    }

    &:hover {

        .hover {
            animation-play-state: running !important;
        }

        .play-bar--play {
            opacity: 0;
        }

        .play-bar--pause {
            opacity: 1;
        }
    }
}

.icon-wordpress {
    width: 80%;

    .graph {
        animation: bounce 1.4s ease-in-out infinite;
    }

    .mouse {
        animation: bounceBFt2 1.6s ease-in-out infinite;
    }

    .wp-logo {
        animation: twinkle2 4s ease-in-out infinite;
    }

    .keyboard {

        $dur: 2.75s;

        [class*=key] {
            animation: typing $dur linear infinite;
        }

        $steps: 1 2 3 4 5 6 7 8 9 10 11 12;

        @each $step in $steps {
            .key-#{$step} {
                animation-delay: calc($dur / 12 * $step);
            }
        }

        @keyframes typing {

            0%,
            2% {
                opacity: 0.6;
            }

            4% {
                opacity: 1;
            }
        }
    }

    .code {

        &-line {
            animation: shrink 1s ease-in-out infinite;
        }

        &-line1 {
            animation-delay: .3s;
        }

        &-line2 {
            animation-delay: .9s;
        }

        &-line3 {
            animation-delay: .2s;
        }

        &-line5 {
            animation-delay: .5s;
        }

        &-line6 {
            animation-delay: .8s;
        }

        &-line8 {
            animation-delay: .4s;
        }
    }

    .hover,
    .keyboard path[class*=key] {
        animation-play-state: paused;
    }

    &:hover {

        .hover,
        .keyboard path[class*=key] {
            animation-play-state: running;
        }
    }
}

.icon-webApps {

    .ruler {
        animation: bounceBF 1.8s ease-in-out infinite;
    }

    .panel {
        animation: twinkle3 3s ease-in-out infinite;

        &2 {
            animation-delay: .5s;
        }

        &3 {
            animation-delay: 1s;
        }

        &4 {
            animation-delay: 1.5s;
        }

        &5 {
            animation-delay: 2s;
        }
    }

    .line-1 {
        transform-origin: 162px;
        animation: shrink 2s ease-in-out infinite;
    }

    .line-2,
    .line-3 {
        transform-origin: 144.3px 0;
        animation: shrink 2s ease-in-out infinite;
    }

    .line-2 {
        animation-delay: -.4s;
    }

    .blink1,
    .blink2,
    .blink3 {
        animation: 6s linear infinite;
    }

    .blink1 {
        animation-name: bubble-b;
    }

    .blink2 {
        animation-name: bubble-m;
    }

    .blink3 {
        animation-name: bubble-t;
    }

    .play-btn {
        opacity: 1;
    }

    .pause-btn {
        opacity: 0;
    }

    .hover {
        animation-play-state: paused;
    }

    &:hover {
        .play-btn {
            opacity: 0;
        }

        .pause-btn {
            opacity: 1;
        }

        .hover {
            animation-play-state: running;
        }
    }
}

.star {
    animation: twinkle 4s ease-in-out infinite;

    &-2 {
        animation-delay: 1s;
    }
}

@keyframes twinkle {

    0%,
    50%,
    100% {
        transform: scale(1);
    }

    25%,
    75% {
        transform: scale(1.6);
    }
}

@keyframes twinkle2 {

    0%,
    50%,
    100% {
        transform: scale(1);
    }

    25%,
    75% {
        transform: scale(1.07);
    }
}

@keyframes twinkle3 {

    0%,
    50%,
    100% {
        transform: scale(1);
    }

    25%,
    75% {
        transform: scale(1.14);
    }
}

@keyframes bubble-t {

    26%,
    80% {
        transform: scale(0);
    }

    20%,
    86% {
        transform: scale(1);
    }
}

@keyframes bubble-m {

    36%,
    70% {
        transform: scale(0);
    }

    30%,
    76% {
        transform: scale(1);
    }
}

@keyframes bubble-b {

    46%,
    60% {
        transform: scale(0);
    }

    40%,
    66% {
        transform: scale(1);
    }
}

@keyframes bounce {
    50% {
        transform: rotate(-6deg);
    }
}

@keyframes bounce2 {
    50% {
        transform: rotate(6deg);
    }
}

@keyframes bounce3 {
    50% {
        transform: rotate(3deg);
    }
}

@keyframes bounceBF {

    0%,
    100% {
        transform: rotate(1.6deg);
    }

    50% {
        transform: rotate(-1.6deg);
    }
}

@keyframes bounceBFt2 {

    0%,
    100% {
        transform: rotate(4.6deg);
    }

    50% {
        transform: rotate(-4.6deg);
    }
}

@keyframes shrink {
    50% {
        transform: scaleX(0.3);
    }
}

@keyframes rotate {
    to {
        transform: rotate(1turn);
    }
}

@keyframes rotate-reverse {
    to {
        transform: rotate(-1turn);
    }
}