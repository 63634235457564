.contact__us-form {
    padding: 1.5em 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 4em) auto 4em;
    gap: 2.25em;
    align-items: center;
    justify-content: center;

    & .contact__us-form_first-name {
        grid-column: 1/3;
        grid-row: 1/2;
    }

    & .contact__us-form_middle-name {
        grid-column: 3/5;
        grid-row: 1/2;
    }

    & .contact__us-form_last-name {
        grid-column: 5/7;
        grid-row: 1/2;
    }

    & .contact__us-form_email {
        grid-column: 1/5;
        grid-row: 2/3;
    }

    & .contact__us-form_receive_email_otp,
    & .contact__us-form_receive_email_verified {
        grid-column: 5/7;
        grid-row: 2/4;
    }

    & .contact__us-form_email_otp {
        grid-column: 5/7;
        grid-row: 2/3;
    }

    & .contact__us-form_email_otp_submit-button {
        grid-column: 5/7;
        grid-row: 3/4;
    }

    & .contact__us-form_organization {
        grid-column: 1/5;
        grid-row: 3/4;
    }

    & .contact__us-form_subject {
        grid-column: 1/7;
        grid-row: 4/5;
    }

    & .contact__us-form_message {
        grid-column: 1/7;
        grid-row: 5/7;
    }

    & .contact__us-form_submit-button {
        grid-column: 5/7;
        grid-row: 7/8;
    }
}