.arrow-link {
    display: inline-block;

    & .arrow-link__box {
        height: min-content;
        width: min-content;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.3em;

        & .text {
            display: inline-block;
            white-space: nowrap;
            color: $primary;
        }

        & .right-arrow-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            & svg {
                height: 0.6em;
                fill: $primary;
            }
        }

        &.reverse {
            flex-direction: row-reverse;

            & .right-arrow-icon {
                transform: rotate(180deg);
            }
        }
    }

    &:hover {
        & .arrow-link__box {
            & .right-arrow-icon {
                & svg {
                    transition: $transition-base;

                    & path {
                        transform: translateX(0%) !important;
                    }
                }
            }
        }
    }
}