/*----------------------------------------*/


/*  06. Footer CSS
/*----------------------------------------*/

.footer {
    opacity: 1;
    z-index: 1;
    & .container {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}

// Footer Info Box 
.footer-info-box {
    cursor: default;
    @media #{$desktop-device} {
        padding-top: 230px;
        padding-bottom: 150px;
    }
    @media #{$tablet-device} {
        padding-top: 200px;
        padding-bottom: 120px;
    }
    @media #{$large-mobile} {
        padding-top: 180px;
        padding-bottom: 100px;
    }
    @media #{$small-mobile} {
        padding-top: 150px;
        padding-bottom: 70px;
    }
    & .title {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 50px;
        color: rgba($white, 0.5);
        @media #{$large-mobile} {
            font-size: 20px;
        }
        @media #{$small-mobile} {
            font-size: 16px;
        }
        &:hover {
            color: $white;
            transition: $transition-base;
        }
    }
    & .mail {
        & a {
            font-size: 2em;
            font-weight: 700;
            color: $primary;
            margin-top: 50px;
            display: inline-block;
            transition: $transition-base;
            @media #{$large-mobile} {
                font-size: 40px;
            }
            @media #{$small-mobile} {
                font-size: 20px;
            }
            &:hover {
                & span {
                    color: $white;
                    transition: $transition-base;
                }
            }
        }
    }
}

.footer-privacy-box {
    & span {
        color: $white;
    }
}

// Footer Copyright & Social 
.footer-copyright-social {
    position: absolute;
    bottom: 0;
    width: inherit;
    display: flex;
    justify-content: space-between;
    padding-bottom: 2em;
    padding-top: 2em;
    @media #{$large-mobile} {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    @media #{$small-mobile} {
        gap: 2em;
    }
}

// Footer Copyright
.footer-copyright {
    cursor: default;
    & a {
        color: $white;
        &:hover {
            & span {
                color: $primary;
                transition: $transition-base;
            }
        }
    }
}

// Footer Privacy
.footer-privacy-policy {
    cursor: default;
    transition: $transition-base;
    &:hover {
        color: $white;
    }
}

// Footer Social 
.footer-social {
    & .social {
        display: flex;
        @media #{$large-mobile} {
            justify-content: center;
            flex-wrap: wrap;
            gap: 0.5em;
        }
        @media #{$small-mobile} {
            gap: 2.5em !important;
        }
        & li {
            &+li {
                margin-left: 1em;
            }
            & a {
                font-size: 16px;
                @media #{$tablet-device, $small-mobile} {
                    font-size: 14px;
                }
                &.twitter {
                    &:hover {
                        color: $twitter;
                    }
                }
                &.facebook {
                    &:hover {
                        color: $facebook;
                    }
                }
                &.linkedin {
                    &:hover {
                        color: $linkedin;
                    }
                }
            }
        }
    }
}