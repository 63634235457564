.stack-game {
    height: 100%;
    width: 100%;

    & .game-window {
        height: 100%;
        width: 100%;

        @media #{$tablet-device, $large-mobile, $small-mobile} {
            height: calc(100vh - 10em) !important;
        }
    }

    & #instructions {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .content {
            padding: 10%;
            background-color: $higher-transparent-dull-black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            cursor: default;

            & p {
                & span {
                    color: $primary;
                }
            }
        }
    }

    & #results {
        position: absolute;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .content {
            padding: 10%;
            background-color: $higher-transparent-dull-black;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5em;
            cursor: default;

            & p {
                & span {
                    color: $primary;
                }
            }

            & .challenge,
            & .lost-the-game {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & .socials {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.5em;

                    & svg {
                        height: 1em;
                        fill: $white;
                        cursor: pointer;
                        transition: $transition-base;

                        &:hover {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }

    & #score {
        position: absolute;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 3em;
        font-weight: bold;
        margin-top: 1em;
        margin-left: 1em;
    }
}