/*--
/*  2.2 - Form CSS	
/*----------------------------------------*/

// Single Form 
.single-form {
    margin-top: 1em;

    & input,
    & textarea {
        width: 100%;
        border: 0;
        background: none;
        border-bottom: 1px solid rgba($white, 0.15);
        font-size: 1em;
        color: rgba($white, 0.5);
        padding: 1.2em 0;
        outline: none;
        transition: $transition-base;

        @include placeholder {
            opacity: 1;
        }

        &:focus {
            border-color: $white;
        }
    }

    & textarea {
        height: 6em;
    }

    p {
        position: absolute;
        color: #bf1650;
        font-size: 0.7em;
        font-weight: 500;
    }
}

.form-message {
    margin-top: 1.5em;
    font-size: 0.8em;
    color: rgba($white, 0.5);
    font-weight: 300;
}

// Form btn
.form-btn {
    padding-top: 2em;

    & button {
        font-size: 1em;
        font-weight: 500;
        color: $primary;
        transition: $transition-base;
        background-color: $highly-transparent-black;
        padding: 0.3em 1.5em;
        border: $stroke-lite solid $primary;
        border-radius: $tiny-border-radius;

        @media #{$laptop-device, $desktop-device, $tablet-device, $large-mobile} {
            font-size: 18px;
        }

        &:hover {
            color: $black;
            background-color: $primary;
        }

        &:disabled {
            background-color: $dull-black;
            border: 1px solid rgba($white, 0.5);
            color: rgba($white, 0.5);
        }
    }
}