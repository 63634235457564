/*--
/*  4.6 - Testimonial CSS
/*----------------------------------------*/

// Testimonial Wrapper
.testimonial-section {
    padding: 8em 0em;
}

.testimonial-wrapper {
    //border-top: 1px solid rgba($white, 0.15);
}

// Single Testimonial 
.single-testimonial {
    background-color: $dull-black;
    height: 20em;
    padding: 3em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1em;

    & .testimonial-author {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3em;

        & .testimonial-icon {
            min-height: max-content;
            min-width: max-content;
            border: 0.33em solid rgba($white, $opacity-less);
            border-radius: 50%;
            background-color: $black;
            overflow: hidden;
            object-fit: cover;
            transition: $transition-base;

            & img {
                width: calc(8em - 0.33em);
                height: calc(8em - 0.33em);
                object-fit: cover;
            }
        }

        & .name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            & .author-name {
                font-size: 2em;
                line-height: 1.3em;
                font-weight: 700;
                color: $white;
            }

            & .author-designation {
                font-size: 1.5em;
                line-height: 1.3em;
                font-weight: 400;
                color: $body-color;
            }
        }
    }


    & .testimonial-content {
        & p {
            font-size: 1em;
            font-weight: 400;
            margin-top: 20px;
            margin-bottom: 0;
            color: $body-color;
        }

        & .view__more {
            color: rgba($white, 0.33);
            transition: $transition-base;

            &:hover {
                color: $primary;
            }
        }
    }

    &::before {
        position: absolute;
        width: 0;
        height: 5px;
        background: $primary;
        content: "";
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        transition: .6s;
    }

    &:hover {
        &::before {
            width: 100%;
            transition: .6s;
        }

        & .testimonial-icon {
            border: 0.33em solid $primary;
        }
    }

    @media #{$tablet-device} {
        font-size: 0.6em;

        & .testimonial-content {
            font-size: 1.3em;
        }
    }

    @media #{$large-mobile, $small-mobile, $extra-small-mobile} {
        font-size: 0.5em;

        & .testimonial-content {
            font-size: 1.3em;
        }
    }
}

// Testimonial  Carousel 
.testimonial-carousel {
    padding-top: 60px;
    position: relative;

    // Responsive
    @media #{$tablet-device} {
        padding-top: 40px;
    }

    @media #{$large-mobile} {
        padding-top: 30px;
    }

    & .swiper-button-prev,
    & .swiper-button-next {
        display: flex;
        align-items: center;
        top: 0;
        opacity: $opacity-less;
        background-color: rgba($white, 0);

        &:focus {
            border: 0px;
            outline: 0px;
        }

        &:after {
            display: none;
        }

        & i {
            visibility: visible;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $transition-base;

            & svg {
                & path {
                    fill: $white;
                }

                height: 20px;
            }
        }

        &:hover {
            opacity: 1;
        }
    }

    & .swiper-button-prev {
        left: auto;
        right: 50px;
    }

    & .swiper-button-next {
        right: 0px;
    }
}